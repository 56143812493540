 .profileboxshow{
    padding: 50px 0px;
 }


 .profzdata h6{
    color: #000;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
 }


 .profzdata input {
    background: #0890af5e;
    width: 100%;
    padding: 10px 17px;
    font-size: 18px;
    border-radius: 3px;
    border: 0px;
    background-color: #0890af0d;
    margin-bottom: 30px;
}

.profzdata input:disabled, .profzdata input:read-only{
    background: #0890af5e;
}


.profzdata textarea {
    background: #0890af5e;
    width: 100%;
    padding: 10px 17px;
    font-size: 18px;
    border-radius: 3px;
    border: 0px;
    background-color: #0890af0d;
    margin-bottom: 30px;
}
    
.profzdata input:hover, .profzdata input:focus, .profzdata input:visited, .profzdata input:focus-within .profzdata  select:hover, .profzdata select:focus, .profzdata select:visited, .profzdata input:focus-within
.profzdata textarea:hover, .profzdata textarea:focus, .profzdata textarea:visited, .profzdata textarea:focus-within
{ 
    box-shadow: none;
    outline: 0;
    border-bottom: 2px solid #0890af;
}


.editprofbtn{
    background: #0890af !important;
    width: auto;
    min-width: 15%;
    padding: 10px !important;
    border: 0px !important;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 25px auto;
    display: table !important;
    text-align: center !important;
}
 

.inregbox label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin: 15px 0px 0px;
    color: #fff;
}

.inregbox label span{
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    color: #fff;
    font-family: inherit;
}

.inregbox input {
    width: 100%;
    padding: 7px 17px;
    border-radius: 1px;
    border: 0px; 
    border-bottom: 2px solid #f3f3f3;
    background-color: transparent;;
    margin-top: 30px; 
    color: #fff;
}

.inregbox select {
    width: 100%;
    padding: 7px 12px;
    border-radius: 1px;
    border: 0px; 
    border-bottom: 2px solid #f3f3f3;
    background-color: transparent;
    margin-top: 30px; 
    color: #fff;
}

.inregbox select option{
    color: #000;
}

.inregbox input:hover, .inregbox input:focus, .inregbox input:visited, .inregbox input:focus-within .inregbox select:hover, .inregbox select:focus, .inregbox select:visited, .inregbox input:focus-within{ 
    box-shadow: none;
    outline: 0;
}

input.subbox {
    background: #0890af;
    width: auto;
    min-width: 30%;
    border: 0px;
    padding: 7px 15px 2px !important;
    color: #fff;
    font-size: 20px !important;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 25px auto;
    display: table;
    text-align: center;
}


.inregbox input::placeholder, .inregbox select::placeholder, .inregbox textarea::placeholder{
    color: #fff;
}

.regbotbox {
    text-align: center;
    color: #fff;
    font-weight: 400;
}

.regbotbox a {
    color: #f19231;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
}





