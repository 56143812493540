
.addnewblogz { 
    padding: 50px 0px;
}

.addblogform{
    position: relative;
}

.addblogform label{
    color:#000;
    font-weight:500;
    font-size: 18px;
    margin-bottom: 3px;
}

.addblogform label span{
    color:#f19231;
    font-size: 14px;
}

.addblogform input {
    width: 100%;
    padding: 7px 17px;
    border-radius: 1px;
    border: 0px;
    border-bottom: 2px solid #0890af;
    background-color: #0890af0d;
    margin-bottom: 30px; 
}

.addblogform input[readonly], .addblogform input[disabled] {
    background: #0890af5e;
    cursor:not-allowed;
}

.addblogform select {
    width: 100%;
    padding: 7px 17px;
    border-radius: 1px;
    border: 0px; 
    border-bottom: 2px solid #0890af;
    background-color: #0890af0d;
    margin-bottom: 30px; 
}

.addblogform textarea {
    width: 100%;
    padding: 7px 17px;
    border-radius: 1px;
    border: 0px; 
    border-bottom: 2px solid #0890af;
    background-color: #0890af0d;
    margin-bottom: 30px; 
}

.ck-editor__editable {
    min-height: 250px; /* Adjust the height as needed */
    margin-bottom: 30px;
    width: 100%;
    padding: 7px 17px;
    border-radius: 1px;
    border: 0px; 
    border-bottom: 2px solid #0890af;
    background-color: #0890af0d;
    margin-bottom: 30px; 
  }

  .multagz .searchWrapper {
    border: 0px;
    padding: 0px;
    min-height: auto;
    border-radius: 0px;
}

.multagz .searchWrapper input.searchBox {
    margin:0px;
    top: 0px;
    z-index: -1;
    position: relative;
}

span.chip.false.false {
    position: relative;
    top: 30px;
}

.multagz .optionContainer input{
    margin:0px 5px;
    width: auto;
}
 

.addblogform input::placeholder, .addblogform select::placeholder, .addblogform textarea::placeholder{
    color: #0890af;
}

.addblogform input:hover, .addblogform input:focus, .addblogform input:visited, .addblogform input:focus-within .addblogform select:hover, .addblogform select:focus, .addblogform select:visited, .addblogform input:focus-within{ 
    box-shadow: none;
    outline: 0;
}

.addblogbtn {
    background: #0890af;
    width: auto;
    min-width: 15%;
    padding:10px;
    border: 0px;
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 25px auto;
    display: table;
    text-align: center;
}

/* TagInput.css */
.tag-input-container {
    display: flex;
    flex-wrap: wrap;  
  }
  
  .tag {
    display: inline-flex;
    align-items: center;
    background-color: #a4d6e2;
    border: 0px;
    border-radius: 3px;
    padding: 0px 3px;
    margin: 0px 3px;
    text-transform: capitalize;
    font-size: 15px;
    max-height: 35px;
}
  .tag button {
    background: none;
    border: none;
    margin-left: 3px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .tag-input-container input { 
    flex: 1 1; 
}
  

.addupimg{
    position: relative;
}

.image-preview {
    position: absolute;
    top: 10px;
    right: 55px;
}

.image-preview img {
    position: absolute;
    width:50px;
    max-height:50px;
    height:auto;
    top:24px;
}