
.post-card {
    background-color: #ffffff;
    padding: 15px 20px 5px;
    border-radius: 10px;
    margin-bottom: 30px;
    display: block;
    position: relative; 
    -webkit-box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
    box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
    -webkit-transition: all 0.9s ease-in-out;
    transition: all 0.9s ease-in-out;
    
}
.post-card__image img {
    border-radius: 10px;
    -webkit-transition: all 0.9s ease-in-out;
    transition: all 0.9s ease-in-out;
    width:100%; 
    min-height: 220px;
    max-height: 220px;
    object-fit: cover;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08), 0 0 0 transparent;
}

.post-card:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}


.category {
    background-color: #3da4a0;
    color: #ffffff;
    font-family: "Inter Tight", sans-serif;
    padding: 6px 12px 6px 12px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 5px;
    text-transform: capitalize;
    position: absolute;
    top: 25px;
    right: 25px;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    transition: all 0.5s ease-in-out;
}

.post-card__title {
    padding: 15px 0px 3px 0px;
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 26px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 70px;
}

.post-card__title-link {
    color: #3da4a0;
    display: inline;
    padding-bottom: 0;
    transition: background-size 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
    background-image: linear-gradient(to bottom, currentColor 0%, currentColor 98%);
    background-size: 0 1px;
    background-repeat: no-repeat;
    background-position: left 100%;
    overflow: visible !important;
    text-decoration: none;
}

.post-card__meta {
    display: flex;
    align-items: center;
    padding-left: 0;
}
.post-card__meta-item {
    text-transform: capitalize;
    font-weight: 600;
    color: #444;
    font-size: 18px; 
}

.post-card__meta li {
    margin: 2px 12px 0px 0px;
    font-size: 16px;
    color: #506172;
    text-transform: capitalize;
    font-weight: 500;
    display: inline-block;
}


.dot {
    width: 5px;
    height: 5px;
    background-color: #f67280;
    border-radius: 50%;
    display: inline-block;
    margin: 2px 6px !important;
}


.post-card__excerpt {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 42px;
    font-size: 18px;
    font-weight: 400;
}

.post-card__meta-item:first-child svg {
    color: #f19231;
    font-size: 18px;
    padding-right: 3px;
    position: relative;
    top: -1px;
}


.post-card__meta-item svg {
    color: #f19231;
    font-size: 14px;
    padding-right: 3px;
    position: relative;
    top: -2px;
}

.post-card__meta-item:last-child svg {
    color: #f19231;
    font-size: 16px;
    padding-right: 3px;
    position: relative;
    top: -1px;
}







