
.regbgbox{
    position: relative;
    padding: 100px 0px;
    background-image: url('../../images/logbg.jfif');
    background-size: cover;
    background-repeat: no-repeat;
}

.fullregbox {
    position: relative; 
    padding: 50px; 
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.15);
            backdrop-filter: blur(10px); 
            border-radius: 10px;
            width: 100%; 
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.inregbox{
    position: relative;
}

.inregbox label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin: 15px 0px 0px;
    color: #fff;
}

.inregbox label span{
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    color: #fff;
}

.inregbox input {
    width: 100%;
    padding: 7px 17px;
    border-radius: 1px;
    border: 0px; 
    border-bottom: 2px solid #f3f3f3;
    background-color: transparent;;
    margin-top: 30px; 
    color: #fff;
}

.inregbox select {
    width: 100%;
    padding: 7px 12px;
    border-radius: 1px;
    border: 0px; 
    border-bottom: 2px solid #f3f3f3;
    background-color: transparent;
    margin-top: 30px; 
    color: #fff;
}

.inregbox select option{
    color: #000;
}

.inregbox input:hover, .inregbox input:focus, .inregbox input:visited, .inregbox input:focus-within .inregbox select:hover, .inregbox select:focus, .inregbox select:visited, .inregbox input:focus-within{ 
    box-shadow: none;
    outline: 0;
}

input.subbox {
    background: #0890af;
    width: auto;
    min-width: 30%;
    border: 0px;
    padding: 7px 15px 2px !important;
    color: #fff;
    font-size: 20px !important;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 25px auto;
    display: table;
    text-align: center;
}


.inregbox input::placeholder, .inregbox select::placeholder, .inregbox textarea::placeholder{
    color: #fff;
}

.regbotbox {
    text-align: center;
    color: #fff;
    font-weight: 400;
}

.regbotbox a {
    color: #f19231;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
}





