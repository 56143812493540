
.blogfulldetz{
    padding: 50px 0px;
}

.post-det-card{
    position: relative;
    width: 100%;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 20px; 
    box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
}

.post-det-card img{
    position: relative;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
    max-height: 500px;
    object-fit: cover;
}

.post-det-card__title {
    margin: 20px 0px 15px;
    font-size: 27px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    color: #3da4a0;
}

.post-det-card__category{
    background-color: #f19231;
    color: #ffffff;
    font-family: "Inter Tight", sans-serif;
    padding: 6px 12px 6px 12px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 5px;
    text-transform: capitalize;
    position: absolute;
    top: 45px;
    right: 45px;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    transition: all 0.5s ease-in-out;
    text-decoration: none;
}

.post-det-card__shorttitle{
    color: #131315;
    display: block;
    padding-bottom: 0;
    transition: background-size 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
    background-image: linear-gradient(to bottom, currentColor 0%, currentColor 98%);
    background-size: 0 1px;
    background-repeat: no-repeat;
    background-position: left 100%;
    overflow: visible !important;
    text-decoration: none;
    font-size: 18px;
    margin-bottom: 15px;
    line-height: 28px;
}

.post-det-card__body{
    color: #131315;
    display: block;
    padding-bottom: 0;
    transition: background-size 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
    background-image: linear-gradient(to bottom, currentColor 0%, currentColor 98%);
    background-size: 0 1px;
    background-repeat: no-repeat;
    background-position: left 100%;
    overflow: visible !important;
    text-decoration: none;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    word-spacing: 5px;
    text-align: justify;
    margin: 15px 0px;
}  

.post-det-card__meta {
    display: block;
    margin-bottom: 12px;
}

.post-det-card__meta span {
    margin-right: 12px; 
    font-weight: 500;
}

.post-det-card__meta span svg{
    color: #f19231;
    font-size: 14px;
    padding-right: 3px;
    position: relative;
    top: -2px;
}

.post-det-card__meta span:last-child svg{
    color: #f19231;
    font-size: 16px;
    padding-right: 3px;
    position: relative;
    top: -2px;
}

.post-det-card__tag {
    background: #3da4a0;
    display: inline;
    padding: 3px 12px;
    border-radius: 5px;
    color: #fff;
}















