
.blogshowlizt{
    padding: 50px 0px;
}

.bloglitview {
    width: 100%;
    table-layout: fixed; /* Ensure cells take fixed width */
}

.bloglitview tr td {
    overflow: hidden;
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bloglitview tr td.tdshow2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Allow line breaks */ 
}

.statact button{
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    text-align: center;
    border: 0px; 
    color: #fff;
    border-radius: 3px;
    padding: 0px;
    margin:0px 3px;
}

.statact button svg { 
    text-align: center;
    margin: 0 auto;
    display: table;
}

.statact button:hover svg{
    color: #fff;
}

.statact button svg:hover{
    color: #fff;
}

 
.bloglitview tr th {
    background: #47a8a5;
    color: #fff;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 0.1px;
}

.blgtbimg img {
    width: 40px;
    height: auto;
    max-height: 40px;
    object-fit: cover;
}