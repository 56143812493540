footer {
    background-color: #2b2b2b;
    position: relative;
}

footer p {
    padding: 15px 0 10px; 
    margin-bottom: 0;
    font-size: 15px; 
    text-align: center;
    color: #d9d9d9;
    text-transform: capitalize;
}

.footer span{
    color:#f19231;
}