
.inbanz{
    padding: 120px 0px 90px; 
    width:100%;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.inbanz:before{ 
    position: absolute;
    content: "";
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0, 0.5);;
}

.innerbanz h3 {
    text-align: center;
    color: transparent;
    z-index: 3;
    font-size: 36px;
    position: relative;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-image: url(../../images/bantext.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-clip: text;
    background-attachment: fixed;
}

.innerbanz p {
    text-align: center;
    color: #fff;
    z-index: 3;
    font-size: 20px;
    position: relative;
    font-weight: 500;  
}
 

.inbanz1{
    background: url(../../images/inban1.jpg);
}

.inbanz2{
    background: url(../../images/inban2.jpg);
}

.inbanz3{
    background: url(../../images/inban3.jpg);
}

.inbanz4{
    background: url(../../images/inban4.jpg); 
}

.inbanz5{
    background: url(../../images/inban5.jpg); 
}

.inbanz6{
    background: url(../../images/inban6.jpg); 
}