

.carousel-item img{
    width: 100%;
    max-height: 500px;
    object-fit: cover;
}


.carousel-item{
    position: relative;
}
 

.carousel-item::before {
    content: "";
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0, 0.5);
}

.carousel-caption{
    position: relative;
    z-index: 3;
    max-width: 700px;
}

.carousel-indicators{
    z-index: 3!important;
}

.carousel-caption h3 {
    position: relative;
    font-size: 50px;
    color: transparent;
    font-weight: 700; 
    letter-spacing: 1px;
    background-image: url(../../images/bantext1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-clip: text;
}

.carousel-caption p{
    position: relative;
    font-size: 20px;
}


.carousel-caption {
    top: 40%;
    left: 0 !important;
    right: 0 !important;
    bottom: auto !important;
    margin: auto;
}

.carousel-control-next, .carousel-control-prev{
    display: none;
}