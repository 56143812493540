

.homeblogz{
    padding:50px 0px;
}

.allbloglinkz {
    background: #0890af;
    width: auto;
    min-width: 15%;
    padding: 10px;
    border: 0px;
    color: #fff;
    border-radius: 5px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 10px auto 40px;
    display: table;
    text-align: center;
    text-decoration: none;
}