.catslidz{
    padding: 50px 0px;
}

.catlist-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1px;
    position: relative; 
    margin-bottom: 16px;
    border-radius: 10px;
}
  
  .catlist-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    max-height: 75px;
    min-height: 75px;
    object-fit: cover;
    position: relative;
}

.catlist-image::after {
    content: "";
    position: absolute;
    z-index: 1;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    top: 10px;
    left: 10px;
    border-radius: 10px;
    background-color: rgb(0 0 0 / 27%);
}

.catlist-item:hover .catlist-name{
    background: #0890af;
    color:#fff;
}

.catlist-count {
    top: 5px;
    right: 5px; 
    text-align: center;
    position: absolute;
    background-color: rgb(255 255 255);
    padding: 3px;
    border-radius: 5px;
    font-size: 12px;
    width: 30px;
    height: auto;
}


.catlist-name {
    margin-top: 12px;
    font-size: 16px;
    text-align: center;
    position: absolute;
    background-color: rgb(255 255 255);
    padding: 7px 12px 3px;
    border-radius: 5px;
    font-weight: 500;
}