
.commonhead {
    padding:  10px 0px 0px;
}

.headlogo{
    width:100%;
    max-width: 220px;
}

.nav-link {
    margin: 0px 10px;
    font-size: 18px!important;
    font-weight: 500!important;
}

.headright {
    margin: 10px auto;
    gap: 3%;
    justify-content: center;
    display: flex;
}

.logbtn {
    padding: 6px 15px 1px!important;
    border-radius: 3px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    background: #0890af !important;
    border: 0px !important;
}

.regbtn{ 
    padding: 6px 15px 1px!important;
    border-radius: 3px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    background: #f19231 !important;
    color:#fff !important;
    text-decoration: none;
    border: 0px !important;
    all: unset; 
}

.blogbtn{
    padding: 6px 15px 1px!important;
    border-radius: 3px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border: 0px !important;
    background: #5dc963 !important;
}

.lead-text{
    text-transform: capitalize;
    cursor: pointer;
    font-size: 18px;
}

.headdropz {
    position: absolute;
    padding: 0px 12px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08), 0 0 0 transparent;
    z-index: 3;
}

.headdropz .link-list{
    padding: 0px;
    margin:0px;
    min-width: 100px;
}

.headdropz .link-list li {
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    font-size: 15px;
    list-style-type: none;
    margin: 0px -12px;
    padding: 5px 12px 10px;
    border-bottom: 1px solid #41a6a2;
    margin-bottom: 3px;
}

 

.headdropz .link-list li:last-child{
    padding-bottom: 0px;
    border-bottom: 0px solid #41a6a2;
}

.headdropz .link-list li a{
    color: #000;
    text-decoration: none; 
    font-size: 16px;
}

.headdropz .link-list li a svg{
    color: #0890af;
}

.headdropz .link-list li:hover a{
    color: #0890af;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: #f19231!important;
}

.lead-text svg {
    font-size: 13px;
    position: relative;
    top: -2px;
    color: #44a7a3;
}

.lead-text svg:last-child{
    color: #000;
    top: 0px;
}

.headright a .regbtn svg {
    font-size: 14px;
    top: -2px;
    position: relative;
}

.headright a .logbtn svg {
    font-size: 16px;
    top: -2px;
    position: relative;
}

.blogbtn svg{
    font-size: 15px;
    top: -3px;
    position: relative;
}