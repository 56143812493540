img.user-avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    padding: 3px;
    margin: 5px;
    border: 1px dotted #ccc;
}

.avatar-container{
    margin:0px auto 10px;
}

.avatar-container {
    margin: 0px auto 10px;
    text-align: center;
}

.profcap input{
    text-transform: capitalize;
}


 