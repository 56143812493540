/* categoryslider.css */

.catslidz{
    padding: 50px 0px;
}

.category-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: relative;
  }
  
  .category-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    max-height: 150px;
    min-height: 150px;
    object-fit: cover;
    position: relative;
}

.category-image::after {
    content: "";
    position: absolute;
    z-index: 1;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    top: 10px;
    left: 10px;
    border-radius: 10px;
    background-color: rgb(0 0 0 / 27%);
}

.category-item:hover .category-name{
    background: #0890af;
    color:#fff;
}

.category-count {
    top: 15px;
    right: 15px; 
    text-align: center;
    position: absolute;
    background-color: rgb(255 255 255);
    padding: 3px;
    border-radius: 5px;
    font-size: 12px;
    width: 30px;
    height: auto;
}


.category-name {
    margin-top: 12px;
    font-size: 16px;
    text-align: center;
    position: absolute;
    background-color: rgb(255 255 255);
    padding: 7px 12px 3px;
    border-radius: 5px;
    font-weight: 500;
}

.react-multiple-carousel__arrow--left {
    left: calc(0% + 1px);
}

.react-multiple-carousel__arrow--right {
    right: calc(0% + 1px);
}

.react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: rgba(0, 0, 0, 0.5);
    min-width: 24px;
    min-height: 24px;
    opacity: 1;
    cursor: pointer;
}

.react-multiple-carousel__arrow::before {
    font-size: 15px; 
}

.react-multiple-carousel__arrow {
    background: #0890af75;
}

.react-multiple-carousel__arrow:hover {
    background: #0890af;
}




