.mainblogz{
    padding: 50px 0px;
}

.blogpagimz {
    margin: 10px auto;
    text-align: center;
    display: table;
}

.blogpagimz .page-item{
    background-color: rgb(255 255 255); 
    border-radius: 20px;
    margin:0px 3px;
}

.blogpagimz .page-item .page-link {
    border-radius: 5px !important;
    padding: 0px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    background: #3da4a096;
    border: 0px;
    color:#fff;
}

.blogpagimz .page-item.active .page-link{
    background: #3da4a0;
    font-weight:600;
}