.forgot-password {
    display: flex;
    justify-content: center;
    align-items: center; 
}

.forgbox {
    position: relative;
    padding: 100px 0px;
    background-image: url('../../images/logbg.jfif');
    background-size: cover;
    background-repeat: no-repeat;
}

.forgot-password-box{
    position: relative;
    padding: 50px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.15);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.forgbox h2 {
    margin-bottom: 20px;
}

.forgbox input {
    width: 100%;
    padding: 7px 17px;
    border-radius: 1px;
    border: 0px;
    border-bottom: 2px solid #f3f3f3;
    background-color: transparent;
    margin-top: 30px;
    color: #fff;
}

.forgbox input:hover, .forgbox input:focus, .forgbox input:visited, .forgbox input:focus-within .forgbox select:hover, .forgbox select:focus, .forgbox select:visited, .forgbox input:focus-within{ 
    box-shadow: none;
    outline: 0;
}

.forgbox input::placeholder{
    color:#fff;
}

.submit-btn {
    background: #0890af !important;
    width: auto !important;
    min-width: 30%;
    border: 0px!important;
    padding: 7px 15px 2px !important;
    color: #fff !important;
    font-size: 20px !important;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 25px auto;
    display: table;
    text-align: center;
}

 
.logoshowbox {
    position: relative;
    width: 100%;
}

.logoshowbox img {
    width: 100%;
}

.back-to-login{
    text-align: center;
    color: #fff;
    font-weight: 400;
}

.back-to-login a{
    color: #f19231;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
}