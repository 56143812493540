
.blog-edit{
    padding:50px 0px;
}

.blog-edit input {
    width: 100%;
    padding: 7px 17px;
    border-radius: 1px;
    border: 0px;
    border-bottom: 2px solid #0890af;
    background-color: #0890af0d;
    margin-bottom: 30px;
}

.blog-edit select {
    width: 100%;
    padding: 7px 17px;
    border-radius: 1px;
    border: 0px;
    border-bottom: 2px solid #0890af;
    background-color: #0890af0d;
    margin-bottom: 30px;
}

.blog-edit textarea {
    width: 100%;
    padding: 7px 17px;
    border-radius: 1px;
    border: 0px;
    border-bottom: 2px solid #0890af;
    background-color: #0890af0d;
    margin-bottom: 30px;
}

.blog-edit label {
    color: #000;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 3px;
}

.blog-edit input:hover, .blog-edit input:focus, .blog-edit input:visited, .blog-edit input:focus-within .blog-edit select:hover, .blog-edit select:focus, .blog-edit select:visited, .blog-edit input:focus-within{ 
    box-shadow: none;
    outline: 0;
}

.blogeditbtnx {
    margin: 20px auto;
    text-align: center;
}

.blogeditbtnx button{
    all:unset;
    border:0px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase; 
    color: #fff;
    width: auto;
    min-width: 15%;
    padding:10px; 
    border-radius: 5px;
    text-align: center; 
}

.updblgbtn {
    background: #0890af!important; 
    
}

.backlistbtn{
    background: #9896a1 !important; 
    margin-left: 10px!important; 
}

.blogedtimg{
    position:relative;
}

.blogedtimg .img-preview {
    position: absolute;
    width: 45px;
    max-height: 45px;
    height: auto;
    top: 30px;
    right: 3px;
}

.litcurname{
    position: relative;
    margin-top:-20px;
}