.logbgbox{
    position: relative;
    padding: 100px 0px;
    background-image: url('../../images/logbg.jfif');
    background-size: cover;
    background-repeat: no-repeat;
}

.fulllogbox {
    position: relative; 
    padding: 50px; 
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.15);
            backdrop-filter: blur(10px); 
            border-radius: 10px;
            width: 100%; 
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.inlogbox{
    position: relative;
}

.inlogbox label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 3px;
}

.inlogbox input {
    width: 100%;
    padding: 7px 17px;
    border-radius: 1px;
    border: 0px; 
    border-bottom: 2px solid #f3f3f3;
    background-color: transparent;
    margin-top: 30px; 
    color: #fff;
}

.inlogbox select {
    width: 100%;
    padding: 7px 17px;
    border-radius: 1px;
    border: 0px; 
    border-bottom: 2px solid #f3f3f3;
    background-color: transparent;
    margin-top: 30px; 
    color: #fff;
}

.logoshowbox{
    position: relative;
    width:100%;
}

.logoshowbox img{
    width:100%;
}

input.subbox {
    background: #0890af;
    width: auto;
    min-width: 30%;
    border: 0px;
    padding: 7px 15px 2px !important;
    color: #fff;
    font-size: 20px !important;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 25px auto;
    display: table;
    text-align: center;
}

.inlogbox input:hover, .inlogbox input:focus, .inlogbox input:visited, .inlogbox input:focus-within .inlogbox select:hover, .inlogbox select:focus, .inlogbox select:visited, .inlogbox input:focus-within{ 
    box-shadow: none;
    outline: 0;
}


.inlogbox input::placeholder, .inlogbox select::placeholder, .inlogbox textarea::placeholder{
    color: #fff;
}

.logbotbox {
    text-align: center;
    color: #fff;
    font-weight: 400;
}

.logbotbox a {
    color: #f19231;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
}

.form-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.remember-me {
    display: flex;
    align-items: center;
}

.remember-me input{
    padding: 10px;
    margin-top: 5px;
}

input#rememberMe {
    margin-right: 5px;
    margin-top: 15x;
}

.remember-me label{
    color: #fff;
    min-width: 120px;
    margin-top: 5px; 
}

.forgot-password {
    text-align: right;
}

.forgot-password a {
    color: #f19231;
    text-decoration: none;
    position: relative;
    top: 3px;
    font-weight: 600;
}